import { gql } from '@apollo/client';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, Grid, MenuItem, Snackbar, styled } from '@mui/material';
import { OnelifeResourceCenterTrigger } from '@onemedical/rna-onelife-resource-center-trigger';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { MouseEvent, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { debounce } from 'lodash';
import Logo from '../components/Logo';
import roles from '../data/roles';
import NavButton from './NavButton';
import NavMenu from './NavMenu';
import PatientSearchBox from './PatientSearchBox';
import UserMenu from './UserMenu';
import { InternalUser } from './models/InternalUser';
import ProviderStatusPopup from './ProviderStatusPopup';

const defaultMinWidth = 1155;
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  padding: theme.spacing(0, 2),
  minWidth: `${defaultMinWidth}px`,
  zIndex: theme.zIndex.tooltip,
}));

const StyledGrid = styled(Grid)(() => ({
  height: '40px',
  overflow: 'hidden',
  flexWrap: 'nowrap',
}));

interface NavBarProps {
  profile: InternalUser;
}

function NavBar({ profile }: NavBarProps) {
  const { providerApptInventoryUi, docsUiUpgrade, massCancellations } = useFlags();

  const [openNavMenu, setOpenNavMenu] = useState<{
    menu: 'appointment' | 'panel';
    element: HTMLElement;
  } | null>(null);

  const openAppointmentsMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenNavMenu({ menu: 'appointment', element: event.currentTarget });
  };
  const openPanelManagementMenu = (event: MouseEvent<HTMLElement>) => {
    setOpenNavMenu({ menu: 'panel', element: event.currentTarget });
  };
  const handleClose = () => {
    setOpenNavMenu(null);
  };

  const oneLifeUrl = process.env.REACT_APP_ONELIFE_URL;
  const serviceAreaQueryParam = (profile?.serviceArea?.serviceAreaGroup?.serviceAreas || [])
    .map((sa) => `service_area[]=${sa.id}-ServiceArea`)
    .join('&');
  const todaysDate = moment().format('YYYY-MM-DD');
  const isAdmin = Boolean(profile?.roles?.map((r) => r.id).includes(roles.ADMIN));
  const isProvider = Boolean(profile?.roles?.map((r) => r.id).includes(roles.PROVIDER));
  const isCalendarAdmin = Boolean(profile?.roles?.map((r) => r.id).includes(roles.CALENDAR_ADMIN));
  const legacyDocsLink = !docsUiUpgrade
    ? `${oneLifeUrl}/admin/inbox?${serviceAreaQueryParam}`
    : undefined;

  const [dimensions, setDimensions] = useState({
    width: defaultMinWidth,
  });

  const [providerStatusVisible, setProviderStatusVisible] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [currentProviderStatus, setCurrentProviderStatus] = useState<boolean | null>(null);

  // Width could be any one of these depending on the content and browser
  // https://javascript.info/size-and-scroll-window#width-height-of-the-document
  // Not all of them update properly upon resizing to SMALLER window, so taking minimum width of these seems more reliable
  const minWidth = Math.min(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.body.clientWidth,
    document.documentElement.clientWidth,
  );

  useEffect(() => {
    // Use minWidth for reason above unless it is set to 0 due to browser/content
    let width = minWidth;
    if (minWidth === 0)
      width = Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.body.clientWidth,
        document.documentElement.clientWidth,
      );

    const debouncedHandleResize = debounce(() => {
      setDimensions({
        width,
      });
    }, 250);

    setDimensions({ width });

    window.onresize = () => {
      debouncedHandleResize();
    };
  }, [minWidth]);

  return (
    <StyledAppBar position="sticky" sx={{ ...dimensions }}>
      <StyledGrid container alignItems="center">
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>
          <NavButton color="inherit" component={NavLink} to="/schedule/tasks" data-cy="tasks-nav">
            Tasks
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            onClick={openAppointmentsMenu}
            endIcon={<FontAwesomeIcon icon={faAngleDown} data-cy="appointments-menu-nav" />}
          >
            Appointments
          </NavButton>
          <NavMenu
            id="simple-menu"
            anchorEl={openNavMenu?.element}
            disableScrollLock
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={openNavMenu?.menu === 'appointment'}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem component={NavLink} to="/schedule/appointments" data-cy="appointments-nav">
              Appointments
            </MenuItem>
            <MenuItem
              component={NavLink}
              to="/schedule/appointment-scanner"
              data-cy="appointment-scanner-nav"
            >
              Appointment Scanner
            </MenuItem>
            {massCancellations &&
              profile?.roles?.some((r) => r && r.id === roles.MASS_CANCELLATION) && (
                <MenuItem
                  component={NavLink}
                  to="/schedule/appointment-cancellation-requests"
                  data-cy="appointment-cancellation-requests-nav"
                >
                  Appointment Cancellations
                </MenuItem>
              )}
            <MenuItem
              component={NavLink}
              to="/schedule/provider-schedule"
              data-cy="provider-schedule-nav"
            >
              Provider Schedule
            </MenuItem>
            {providerApptInventoryUi && (
              <MenuItem
                component={NavLink}
                to="/schedule/provider-appt-inventory"
                data-cy="provider-appt-inventory-nav"
              >
                Provider Appointment Inventory
              </MenuItem>
            )}
            <MenuItem
              component={NavLink}
              to="/schedule/phlebotomy-visits"
              data-cy="phlebotomy-visits-nav"
            >
              Phlebotomy Visits
            </MenuItem>
            <MenuItem
              component="a"
              href={`${oneLifeUrl}/admin/appointments/unregistered`}
              data-cy="unregistered-patients-nav"
            >
              Unregistered Patients
            </MenuItem>
            <MenuItem
              component="a"
              href={`${oneLifeUrl}/admin/offices/${profile?.homeOffice?.id}/schedules?date=${todaysDate}`}
              data-cy="office-schedule-nav"
            >
              Office Schedule
            </MenuItem>
            <MenuItem
              component="a"
              href={`${oneLifeUrl}/admin/video_appointments`}
              data-cy="virtual-visits-nav"
            >
              Virtual Visits
            </MenuItem>
            {isCalendarAdmin && (
              <MenuItem
                component={NavLink}
                to="/schedule/templates"
                data-cy="schedule-templates-nav"
              >
                Schedule Templates
              </MenuItem>
            )}
          </NavMenu>
        </Grid>
        <Grid item>
          {legacyDocsLink && (
            <NavButton color="inherit" href={legacyDocsLink} data-cy="docs-nav">
              Docs
            </NavButton>
          )}
          {docsUiUpgrade && (
            <NavButton component={NavLink} color="inherit" to="/admin/documents" data-cy="docs-nav">
              Docs
            </NavButton>
          )}
        </Grid>
        <Grid item>
          <NavButton color="inherit" href={`${oneLifeUrl}/fax/send_fax`} data-cy="faxes-nav">
            Faxes
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            component={NavLink}
            to="/admin/contacts"
            data-cy="contacts-nav"
          >
            Contacts
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            component={NavLink}
            to="/admin/provider-directory"
            data-cy="providers-nav"
          >
            Providers
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            href={process.env.REACT_APP_TEMPLATE_MANAGER_URL}
            data-cy="Template Manager"
          >
            Template Manager
          </NavButton>
        </Grid>
        <Grid item>
          <NavButton
            color="inherit"
            onClick={openPanelManagementMenu}
            endIcon={<FontAwesomeIcon icon={faAngleDown} data-cy="panel-management-nav" />}
          >
            Panel Management
          </NavButton>
          <NavMenu
            id="simple-menu"
            anchorEl={openNavMenu?.element}
            disableScrollLock
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={openNavMenu?.menu === 'panel'}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem component={NavLink} exact to="/panel-management/home" data-cy="panel-nav">
              Panel Management Home
            </MenuItem>
            <MenuItem
              component={NavLink}
              to="/panel-management/admissions/"
              data-cy="admissions-nav"
            >
              Admissions Board
            </MenuItem>
          </NavMenu>
        </Grid>
        <Box flexGrow={1} />
        <Grid item sx={{ marginRight: 1, lineHeight: 1 }}>
          {/* TODO: nonce shouldn't be required */}
          <OnelifeResourceCenterTrigger nonce={undefined} />
        </Grid>
        <Grid item sx={{ marginRight: 1 }}>
          <PatientSearchBox isAdmin={isAdmin} data-cy="patient-search-nav" />
        </Grid>
        <Grid item>
          {profile && (
            <UserMenu
              profile={profile}
              currentRoles={profile.roles}
              data-cy="profile-name-nav"
              passProviderStatusVisibility={setProviderStatusVisible}
              currentlyOut={currentProviderStatus}
            />
          )}
        </Grid>
      </StyledGrid>
      <Box>
        {providerStatusVisible && isProvider && (
          <ProviderStatusPopup
            passProviderStatusVisibility={setProviderStatusVisible}
            profile={profile}
            setOpenToast={setOpenToast}
            setToastMessage={setToastMessage}
            setCurrentlyOut={setCurrentProviderStatus}
          />
        )}
      </Box>
      <Snackbar
        data-cy="provider-status-toast"
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openToast}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
        sx={{ color: 'black' }}
      />
    </StyledAppBar>
  );
}

NavBar.fragments = {
  profile: gql`
    fragment NavBarProfile on InternalUser {
      roles {
        id
      }
      homeOffice {
        id
      }
      serviceArea {
        id
        serviceAreaGroup {
          id
          serviceAreas {
            id
          }
        }
      }
    }
  `,
};

export default NavBar;
